.Container {
    display: flex;
    flex-direction: column;
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 18px;
  
    .line {
        border-bottom: 2px solid #6838B6;
    }
   
    .errorContainer{
     
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        img{
            max-width: 70px;
            margin-bottom: -30px;
            z-index: 1;
        }
 
        .errorBox{
          
            z-index: 0;
     
            width: 100%;
            .errorTitle{
                background-color: #17B88A;
                padding: 5px;
                border-radius: 16px 16px 0 0  ;
            max-width: 512px;
                font-family: verveine, sans-serif;
                color: white;
                font-size: 28px;
                text-align: center;

                
            }
            .errorContent{
                border: 2px solid #17B88A;
                padding: 10px;
                border-radius:  0 0 16px 16px ;
                div{

                p{
                    text-align: center;
                    font-size: 14px;
                }
}
            }
        
        }
    }
    .imageContainer{
            margin-bottom: -4px;
            z-index: 1;
            display: flex;
            img{
            max-width: 70px;
          
        }
        }
}