.Container {
    display: flex;
    flex-direction: column;
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 18px;
    .line {
        border-bottom: 2px solid #55b48d;
    }

    .passosContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        gap: 25px;
      
        img {
            max-width: 400px;
            width: 100%;
            cursor: pointer;
        }
    }
}
.passo1Container {
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 0;

    strong {
        font-family: "Verdana-Bold";
    }
    .passo1Title {
        max-width: 270px;
        width: 100%;
        padding: 13px;
        font-family: verveine, sans-serif;
        background-color: #f5a621;
        color: #ce516c;
        font-size: 23px;
        border-radius: 0 26px 26px 0;
    }

    .passo1Header {
        padding: 18px;
        display: flex;
        align-items: center;
        P {
            font-size: 15px;
            margin: 0 42px;
        }
        img {
            max-width: 200px;
            width: 100%;
        }
    }

    .passo1Content {
        padding: 18px;
        P{
            font-size: 15px;
        }
        .checkContainer{
            display: flex;
            flex-direction: column;
            margin: 0 50px;
            div{
                display: flex;
                align-items: center;
                gap: 10px;
          
             
            }
        }
        @media(max-width:500px){
            .checkContainer{
                margin: 0;
            }
        }
        .dizeresContainer{
              display: flex;
            justify-content: center;
            gap: 20px;
              img{
                max-width: 250px;
                width: 100%;
              }
        }
    }
}
.passo2Container{
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 0;

    strong {
        font-family: "Verdana-Bold";
    }
    .passo2Title {
        max-width: 270px;
        width: 100%;
        padding: 13px;
        font-family: verveine, sans-serif;
        background-color: #17B88A;
        color: #142640;
        font-size: 23px;
        border-radius: 0 26px 26px 0;
    }
      .passo2Header {
        padding: 18px;
        display: flex;
        align-items: center;
        P {
            font-size: 15px;
        }
        img {
            max-width: 200px;
            width: 100%;
        }
    }

    .passo2Content {
        padding: 18px;
    P{
        font-size: 15px;
    }

        .checkContainer{
            display: flex;
            flex-direction: column;
            margin: 0 50px;
            div{
                display: flex;
                align-items: center;
                gap: 10px;
          
             
            }
        }
        @media(max-width:500px){
            .checkContainer{
                margin: 0;
            }
        }
        .dizeresContainer{
              display: flex;
            justify-content: center;
            gap: 20px;
         
              img{
                max-width: 250px;
                width: 100%;
              }
        }
    }
}

.passo3Container{
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 0;

    strong {
        font-family: "Verdana-Bold";
    }
    .passo3Title {
        max-width: 270px;
        width: 100%;
        padding: 13px;
        font-family: verveine, sans-serif;
        background-color: #2E85C7;
        color: white;
        font-size: 23px;
        border-radius: 26px 0   0 26px;
        margin-left: auto;
    }
    .passo3Header {
        padding: 18px;
        display: flex;
        align-items: center;
        gap: 12px;
        P {
            font-size: 15px;
        }
        img {
            max-width: 200px;
            width: 100%;
        }
    }
    .passo3Content {
        padding: 18px;
    P{
        font-size: 15px;
    }

        .checkContainer{
            display: flex;
            flex-direction: column;
            margin: 0 50px;
            div{
                display: flex;
                align-items: center;
                gap: 10px;
          
             
            }
        }
        @media(max-width:500px){
            .checkContainer{
                margin: 0;
            }
        }
        .dizeresContainer{
              display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px;
          
         
              img{
                max-width: 250px;
                width: 100%;
              }
        }
    }
}
