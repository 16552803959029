.Container{
   
 display: flex;
 flex-direction: column;
 padding: 18px;
 
    h1{
        font-family: "Verdana",Arial, Helvetica, sans-serif;
       
    }

    .line{
        border-bottom: 2px solid #F5A621;
    }
    P{
        font-family: "Verdana",Arial, Helvetica, sans-serif;
        color: black;
    }
    .baloonContainer{
          
        display: flex;
        flex-direction: column;
        justify-content: start;
       
       
        img{
            max-width: 200px;
            z-index:1 ;
        }
        .baloonDescription{
            z-index:0 ;
            margin-top: -54px;
            background-color: #F5A621;
            padding: 16px;
            border-radius: 40px;
        }
    }

    @media(max-width:630px){
        .baloonContainer{
          align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }    
    }
}