@font-face {
    font-family: "Verdana";
    src:  url("./fonts/verdana.ttf") format("truetype");

}
@font-face {
    font-family: "Verdana-Bold";
    src:  url("./fonts/verdana-bold.ttf") format("truetype");
    font-weight: bold;
}
@font-face {
    font-family: "Verdana-Itallic";
    src:  url("./fonts/verdanai.ttf") format("truetype");

}



body{
    font-family: verveine, sans-serif;
    padding: 0;
    margin: 0;
    background-color: #17B88A;
}