.Container {
    display: flex;
    flex-direction: column;
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 18px;
    font-size: 15px;
  
    .line {
        border-bottom: 2px solid #6838B6;
    }

    .pointsContainer{

         display: flex;
         flex-direction: column;
        .pointsBox{
            display: flex;
              gap: 15px;
        }
    }
}

.banner1Container{
    display: flex;
    flex-direction: column;
   
    max-width: 574px;

    div{
        img{
            width: 100%;
        }
      
    }
}