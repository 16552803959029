.Container {
    display: flex;
    flex-direction: column;
    font-family: "Verdana", Arial, Helvetica, sans-serif;
    padding: 18px;
    .line {
        border-bottom: 2px solid #6838B6;
    }

    .modalFooter{
          
          display: flex;
          justify-content: center;
          align-items: center;
        div{
            img{
                max-width: 236px;
            }
        }
    }
}