.homeContainer{
    position: relative;
  background-color: #17B88A;
 padding: 0;
    max-width: 1500px;
    margin: 0 auto;
  display: flex;
  flex-direction: column;

  .mobileNavShow{
    display: none;
  }

  @media(max-width:850px){
    .mobileNavShow{
        display: block;
        max-width:32px;
        margin-left:20px;
        margin-top:40px;
        cursor: pointer;
    }

  }

    .topIcon{
        width: 80%;
        margin-top: 40px;
    
    }
    @media(max-width:850px){

      .topIcon{
        width: 100%;
        margin-bottom: 20px;
      }
    }

    
    .mobileButtons{
        position: absolute;
        background-color: white;
        left: 0;
        width: 300px;
        height: 100vh;
        padding: 24px;
        .mobilenavButtons{
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 20px;
            a{
                border: 0;
                background-color: transparent;
                cursor: pointer;
                font-family: verveine, sans-serif;
                font-size: 25px;
                color: #00223E;
            }
            img{
              cursor: pointer;
            }
        }

      }
    .mainContent{
        
     
      display: flex;
      gap: 140px;
      justify-content: center;

    
        .navButtons{
            display: flex;
            flex-direction: column;
            align-items: start;
            a{
                border: 0;
                background-color: transparent;
                cursor: pointer;
                font-family: verveine, sans-serif;
                font-size: 25px;
                color: #00223E;
                border-radius: 20px;
            }
        }

        @media(max-width:850px){
          .navButtons{
            display: none;
          }
        }
        .modalContainer{
                background-color: white;
            height: 650px;
            width: 575px;
        
            overflow: scroll;
            overflow-x: hidden;


            .modal2Container{
              padding: 0;
              margin: 0;
            }
        }
        
    }
    .bottomIcon{
        width: 100%;
        display: flex;
        margin-top: auto;
        
    }
}